import '../../styles.css';

function Events_FRC(){
    return(
        <div className="FRC">
            <strong className='article-title'>FRC 1466 Calendar</strong>

            {/* Google Calendar go here */}
            <p>
                Work in progress, will have some info here soon
            </p>
        </div>
    );
}
export default Events_FRC;