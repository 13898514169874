import '../../styles.css';

function Sponsors_FLL(){
    return(
        <div className="FLL">
            <p>
                Work in progress, will have some info here soon
            </p>
        </div>
    );
}
export default Sponsors_FLL;