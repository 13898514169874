import '../../styles.css';

function Sponsors_FRC(){
    return(
        <div className="FRC">
            <p>
                Hopefully soonn
            </p>
        </div>
    );
}
export default Sponsors_FRC;