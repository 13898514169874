import '../../styles.css';

function FTC(){
    return(
        <div className="FTC">
            <p>
                Work in progress, will have some info here soon
            </p>
        </div>
    );
}
export default FTC;