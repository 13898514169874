import '../../styles.css';

function Awards_FLL(){
    return(
        <div className="FLL">
            <p>
                Work in progress, will have some info here soon
            </p>            
        </div>
    );
}
export default Awards_FLL;