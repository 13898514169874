import '../../styles.css';

function Sponsors(){
    return(
        <div className="Sponsors">
            <p>
                This page is a work in progress, we will have more info here soon. <br/> <br/> For inquiries, regardless of team, 
                please email webbrobotics@gmail.com. We are currently in the process of revamping our team communications system, 
                so this method could be different in the future. 
            </p>

        </div>
    );
}
export default Sponsors;